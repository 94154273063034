<template>
  <v-card class="chat_list">
    <div class="chat_header">
      <h2>Messages</h2>
    </div>
    <v-list two-line class="list" v-if="chats.length">
      <v-list-item-group
        v-model="selectedItem"
        active-class="active"
        mandatory
        color="primary"
      >
        <v-list-item
          class="chat_item"
          v-for="chat in chats"
          :class="{ active: chat.ID === chatID }"
          :key="chat.ID"
          ripple
          @click="open(chat.id)"
        >
          <v-list-item-content class="chat_item_content">
            <v-list-item-title>{{
              getOtherUser(chat.participants).company_name
            }}</v-list-item-title>
            <v-list-item-subtitle class="chats_content_preview">
              <p class="last_message" v-if="chat.last_message">
                {{ chat.last_message.content }}
              </p>
              <p v-else class="last_message">No Messages Yet</p>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="chat.unread">
            <v-badge dot color="secondary"></v-badge>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-card-text class="unselected-text" v-else>
      <p>You currently have no active chats</p></v-card-text
    >
  </v-card>
</template>

<script>
export default {
  props: {
    chatID: { type: String, default: null }
  },
  data() {
    return {
      item: null
    };
  },
  methods: {
    open(id) {
      this.$router.push({
        name: "chat",
        params: { id }
      });
    },
    getOtherUser(participants) {
      for (const [id, participant] of Object.entries(participants)) {
        if (id !== this.self) {
          return participant;
        }
      }
    }
  },
  computed: {
    selectedItem: {
      get() {
        if (this.item == null) {
          let chatID = this.chatID;
          let item = this.chats?.findIndex(c => c.id === chatID);
          return item;
        }
        return this.item;
      },
      set(i) {
        this.item = i;
      }
    },
    chats() {
      let chats = this.$store.getters.sortedChats;
      let last_seen = this.$store.getters.last_seen;

      chats.map(c => {
        if (c?.last_message?.created_at > last_seen && !c.has_seen_last) {
          c.unread = true;
        } else {
          c.unread = false;
        }
      });
      return chats;
    },
    self() {
      return this.$store.getters.self;
    }
  }
};
</script>

<style scoped>
.unselected-text {
  font-size: 16px;
}

::v-deep .v-list-item {
  width: 100%;
}
.active {
  background-color: transparent;
  z-index: 1;
  color: white;
  height: 80px !important;
}
.active:before {
  border-radius: 4px;
  background-color: var(--v-primary-base);
  opacity: 1 !important;
  z-index: 0;
}
.active * {
  color: white;

  z-index: 1;
}
.chat_header {
  background-color: var(--v-primary-base);
  color: #ffffff;
  height: 60px;
  padding: 5px;
  text-align: center;
  font-size: 0.9rem;
  line-height: 50px;
}
.chat_list {
  margin: 0 auto;
  padding: 0 0 5px 0;
  width: 95%;
  height: fit-content;
}
.chat_item {
  height: fit-content;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.v-list-item__title {
  align-self: flex-start;
}

.chat_item_content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  flex: unset;
  width: 100%;
  justify-self: flex-start;
}

.chat_item_content * {
  white-space: pre-wrap;
  text-overflow: ellip;
}

.chats_content_title {
  font-size: 1.4rem;
}
.chats_content_preview {
  color: #808080;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  width: 100%;
}

.active .chats_content_preview {
  border-color: white;
}

.chats_content_preview p {
  margin: 0;
}
.last_message {
  max-width: 300px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.last_message_date {
  float: right;
  font-size: 0.8rem;
}
</style>
