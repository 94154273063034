import { render, staticRenderFns } from "./chatbox.vue?vue&type=template&id=3cb8a2df&scoped=true&"
import script from "./chatbox.vue?vue&type=script&lang=js&"
export * from "./chatbox.vue?vue&type=script&lang=js&"
import style0 from "./chatbox.vue?vue&type=style&index=0&id=3cb8a2df&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb8a2df",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VForm,VIcon,VTextField})
