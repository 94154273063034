<template>
  <v-card class="message_container">
    <div class="header" v-if="chatID">
      <h2>{{ otherUserName }}</h2>

      <p>
        <v-icon
          small
          class="last_seen_icon"
          :class="{ last_seen_icon_offline: otherUserLastSeen != 'online' }"
          >brightness_1</v-icon
        >
        <template v-if="otherUserLastSeen != 'online'">Last Seen: </template>
        <span>{{ otherUserLastSeen }}</span>
      </p>
    </div>
    <div class="header unselected" v-else>
      <h2>No chat selected</h2>
    </div>
    <div class="messages scroll-shadow" ref="messagesContainer">
      <pre
        v-for="msg in messages"
        :key="msg.message"
        :class="{ received: msg.sender_id != self }"
      >
        {{ msg.content }}
      </pre>
    </div>
    <div class="message_editor">
      <v-form ref="form" @submit.prevent="sendMessage">
        <v-text-field
          v-model="message"
          :rules="validation"
          label="Message"
          outlined
          append-outer-icon="send"
          color="primary"
          @click:append-outer="sendMessage"
          :disabled="sendMessageDisabled"
        ></v-text-field>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import { GetLastSeen } from "@/api/messages/get_last_seen.js";
import { SendMessage } from "@/api/messages/send_message.js";
import { GetMessages } from "@/api/messages/get_messages.js";

export default {
  props: {
    chatID: { type: String, default: null }
  },
  data() {
    return {
      message: "",
      validation: [],
      last_seen: {}
    };
  },
  async created() {
    await this.getLastSeen();
    if (this.chatID != null) {
      await GetMessages(this.chatID);
    }
    this.interval = setInterval(() => this.getLastSeen(), 30000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    message() {
      this.validation = [];
    },
    chatID: {
      immediate: true,
      async handler() {
        await this.getLastSeen();
        if (this.chatID != null && this.messages == null) {
          await GetMessages(this.chatID);
        }
        clearInterval(this.interval);

        this.interval = setInterval(() => this.getLastSeen(), 30000);
      }
    }
  },
  updated() {
    this.$nextTick(() => this.scrollToEnd());
  },
  methods: {
    async sendMessage() {
      this.validation = [v => !!v || "A message is required"];
      let self = this;
      setTimeout(function() {
        if (self.$refs.form.validate()) {
          SendMessage(self.chat.id, self.message);
          self.$refs.form.reset();
        }
      });
    },
    async getLastSeen() {
      this.last_seen = await GetLastSeen(this.chatID);
    },
    scrollToEnd: function() {
      let content = this.$refs.messagesContainer;
      content.scrollTop = content.scrollHeight;
    }
  },
  computed: {
    sendMessageDisabled() {
      return this.chatID == null;
    },
    self() {
      return this.$store.getters.self;
    },
    chat() {
      return this.$store.getters.chat(this.chatID);
    },
    messages() {
      this.$store.commit("set_chat_has_seen_last", {
        chat_id: this.chatID,
        val: true
      });
      return this.$store.getters.chat_messages(this.chatID);
    },
    otherUser() {
      let participants = this.chat.participants;
      if (participants == null) {
        return null;
      }
      return participants[
        Object.keys(participants).find(id => {
          return id != this.self;
        })
      ];
    },
    otherUserName() {
      let user = this.otherUser;
      if (user == null) {
        return "";
      }

      return user.company_name;
    },
    otherUserLastSeen() {
      let user = this.otherUser;
      let last_seen = user != null ? this.last_seen[user.id] : "";
      if (last_seen !== "online") {
        last_seen = last_seen != null ? last_seen : "";
        let now = DateTime.local();
        last_seen = DateTime.fromISO(last_seen);
        if (last_seen.hasSame(now, "day")) {
          last_seen = last_seen.toLocaleString(DateTime.TIME_24_SIMPLE);
        } else if (Math.round(now.diff(last_seen, "days").days) == 1) {
          last_seen = `yesterday ${last_seen.toLocaleString(
            DateTime.TIME_24_SIMPLE
          )}`;
        } else {
          last_seen = last_seen.toLocaleString(DateTime.DATE_SHORT);
        }
      }
      last_seen = last_seen != "Invalid DateTime" ? last_seen : "";
      return last_seen;
    }
  }
};
</script>

<style scoped>
.message_container {
  display: grid;
  grid-template-rows: 60px 1fr auto;
  margin: 0 auto;
  height: calc(100vh - 150px);
}

.header {
  background-color: var(--v-primary-base);
  color: #ffffff;
  height: 60px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 1;
}

.unselected {
  display: flex;
  align-items: center;
}

.header h2 {
  padding: 0 10px;
}

.header p {
  font-size: 12px;
  margin: 0;
  padding: 0 0 0 10px;
  text-transform: capitalize;
}

.last_seen_icon {
  color: var(--v-secondary-base);
  font-size: 16px;
  margin-bottom: 2px;
  padding-right: 2px;
}

.last_seen_icon_offline {
  color: grey;
}

.messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px 30px;
}

.messages pre {
  border-radius: 18px 18px 0 18px;
  padding: 10px 20px;
  margin-bottom: 5px;
  background-color: var(--v-primary-base);
  color: white;
  width: fit-content;
  flex-basis: flex-end;
  align-self: flex-end;
  max-width: 60%;
  overflow-wrap: anywhere;
  font-family: inherit;
  max-width: 60%;
  word-wrap: break-word;
  white-space: pre-line;
  z-index: 0;
}

pre.received {
  border-radius: 18px 18px 18px 0;
  background-color: #e0e0e0;
  color: black;
  flex-basis: flex-start;
  align-self: flex-start;
  max-width: 60%;
}

.message_editor {
  padding: 20px 20px 0;
}
</style>
