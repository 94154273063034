<template>
  <div class="chat_container">
    <sidebar :chat-i-d="chatID" />
    <chatbox :chat-i-d="chatID" style="width:100%" />
  </div>
</template>

<script>
import Chatbox from "@/components/chat/chatbox.vue";
import Sidebar from "@/components/chat/sidebar.vue";

export default {
  components: {
    chatbox: Chatbox,
    sidebar: Sidebar
  },
  computed: {
    chatID() {
      let chatID = this.$route.params.id;
      if (chatID === "empty") {
        chatID = "";
      }
      return chatID;
    }
  }
};
</script>

<style scoped>
.chat_container {
  display: grid;
  grid-template-columns: 25% auto;
  gap: 25px;
  width: 50%;
  margin: auto;
}

.no-messages {
  width: 50%;
  margin: auto;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-messages p {
  font-size: 20px;
  padding: 5px;
}

@media screen and (max-width: 1920px) {
  .chat_container {
    width: 75%;
  }
}

@media screen and (max-width: 1366px) {
  .chat_container {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .chat_container {
    grid-template-columns: auto;
  }
}
</style>
