import { MessageService } from "@/api/instance";
import { DeAuthSession } from "@/api/de-auth";

export const GetLastSeen = async chat_id => {
  try {
    let {
      data: { participants }
    } = await MessageService().get(`/chats/${chat_id}/last_seen`);
    return participants;
  } catch (e) {
    DeAuthSession(e);
    throw e;
  }
};
